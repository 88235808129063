"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.error.cause.js");
var _jsMd = _interopRequireDefault(require("js-md5"));
var _stationSetting = require("@/assets/js/stationSetting");
var _vuePuzzleVcode = _interopRequireDefault(require("vue-puzzle-vcode"));
var _clipboard = _interopRequireDefault(require("clipboard"));
var Account = _interopRequireWildcard(require("@/model/user/account"));
var Settings = _interopRequireWildcard(require("@/model/settings"));
var _message = require("@/components/dialog/message");
var _security = require("@/model/user/security");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _ = _interopRequireDefault(require("../../assets/img/rePhoto/1.png"));
var _2 = _interopRequireDefault(require("../../assets/img/rePhoto/2.png"));
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
var _encryption = _interopRequireDefault(require("@/assets/js/encryption"));
var _i18n = require("@/assets/js/i18n");
var _vuex = require("vuex");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _Geetest = _interopRequireDefault(require("@/components/Geetest.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//页面内引用插件
// import Cookies from "js-cookie";
var _default = {
  data() {
    return {
      img1: _.default,
      img2: _2.default,
      imgs: [],
      codeVisible: false,
      isShow: false,
      emailCountDown: {
        countDown: -1,
        // 倒计时计数
        countDownTimer: null,
        // 倒计时容器
        countDownStatus: false // 倒计时点击状态
      },

      mobileCountDown: {
        countDown: -1,
        // 倒计时计数
        countDownTimer: null,
        // 倒计时容器
        countDownStatus: false // 倒计时点击状态
      },

      passwordType: "password",
      pwdTypeConfird: "password",
      serviceUrl: "",
      privacyUrl: "",
      checked: true,
      advanced: true,
      isUeex: false,
      inviteCodeDisabled: false,
      isBitda: false,
      registerLoading: false,
      // 登录loading
      currentType: "2",
      // 当前选项
      registerData: {},
      // 登录数据
      all_areacode: [],
      // 所有区号
      subType: "",
      codeTitle: "",
      subTitle: "",
      secret: "",
      //谷歌秘钥
      areaCode: "",
      //地区
      submitLoading: false,
      codeData: {
        mobile_code: "",
        email_code: "",
        google_code: ""
      },
      isShowGeet: false,
      geetParam: null,
      isFetch: false,
      formRules: {
        area: [{
          required: true,
          message: this.translateTitle(`请选择区号`),
          trigger: "blur"
        }],
        mobile: [{
          required: true,
          message: this.translateTitle(`请输入手机号`),
          trigger: "blur"
        }],
        account: [{
          required: true,
          message: this.translateTitle(`请输入用户名`),
          trigger: "blur"
        }, {
          pattern: /^[A-Za-z][A-Za-z0-9_]{4,15}$/,
          message: this.translateTitle(`用户名长度需为5～16位，以字母开头，数字字母下划线组成`),
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: this.translateTitle(`请输入邮箱`),
          trigger: "blur"
        }, {
          pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/,
          message: this.translateTitle(`邮箱格式不正确`),
          trigger: "blur"
        }],
        pwd: [{
          required: true,
          message: this.translateTitle(`请输入密码`),
          trigger: "blur"
        }, {
          min: 6,
          max: 20,
          message: this.translateTitle(`密码长度为 6-20 位字符`),
          trigger: "blur"
        }, {
          pattern: /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/,
          message: this.translateTitle(`密码必须包含英文和数字，不能包含空格`),
          trigger: "blur"
        }
        // { pattern: /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/, message: '密码必须包含英文和数字，不能包含空格'}
        ],

        confirm_pwd: [{
          validator: this.confirmBlur,
          trigger: "blur"
        }],
        mobile_code: [{
          required: true,
          message: this.translateTitle(`请输入手机验证码`),
          trigger: "blur"
        }, {
          max: 6,
          message: this.translateTitle(`验证码应为 6 位数字`),
          trigger: "blur"
        }],
        gogle_code: [{
          required: true,
          message: this.translateTitle(`请输入谷歌码`),
          trigger: "blur"
        }, {
          max: 6,
          message: this.translateTitle(`谷歌码应为 6 位数字`),
          trigger: "blur"
        }]
      },
      codeRules: {
        mobile_code: [{
          required: true,
          message: this.translateTitle(`请输入正确的6位手机验证码`),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`请输入正确的6位手机验证码`)
        }],
        email_code: [{
          required: true,
          message: this.translateTitle(`请输入正确的6位邮箱验证码`),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`请输入正确的6位邮箱验证码`)
        }],
        google_code: [{
          required: true,
          message: this.translateTitle(`请输入正确的6位谷歌码`),
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`请输入正确的6位谷歌码`)
        }]
      },
      rebate_value: "--"
    };
  },
  async created() {
    let clause = JSON.parse(sessionStorage.getItem("clause"));
    this.serviceUrl = clause[0].url;
    this.privacyUrl = clause[1].url;
    await this.initialData();
    this.getAreaCode();
    // this.GetGoogleSecret();

    document.onkeyup = e => {
      let keycode = document.all ? event.keyCode : e.which;
      // 在登录界面 且 按得键是回车键enter
      // if (this.$route.path == "/register" && keycode === 13) {
      //   this.registerClick();
      // }
    };

    if (_stationSetting.VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w") {
      this.imgs.push(_.default);
    } else {
      this.imgs.push(_2.default);
    }
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  mounted() {
    if (JSON.stringify(this.$route.params) != "{}") {
      let name = this.$route.params.name;
      let val = this.$route.params.val;
      if (name == "phone") {
        this.currentType = "3";
        this.registerData.mobile = val;
      } else if (name == "email") {
        this.currentType = "2";
        this.registerData.email = val;
      } else if (name == "account") {
        this.currentType = "1";
        this.registerData.account = val;
      } else {
        this.currentType = "2";
      }
    } else {
      this.currentType = "2";
    }
    this.registerData.invite_code = this.$route.query.ref || this.$route.query.invite_code;
    if (this.registerData.invite_code) {
      this.inviteCodeDisabled = true;
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    success(geetParam) {
      this.geetParam = geetParam;
      if (!this.isFetch) {
        this.registerClick();
      } else {
        this.fetchCode();
      }
      // this.handLoginSuccess(this.sucessData, this.loginStatus, this.loginMsg);
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },

    onCloseVcode() {
      this.isShow = false;
    },
    registerClickShow() {
      this.$refs.register_ref.validate(async valid => {
        if (!valid) {
          return;
        }
        if (!this.checked) {
          (0, _message.showMessage)({
            message: this.translateTitle(`请勾选用户协议`),
            type: "info"
          });
          return;
        }
        this.isFetch = false;
        // this.isShow = true;
        this.isShowGeet = !this.isShowGeet;
      });
    },
    onToogleAdvanced() {
      this.advanced = !this.advanced;
    },
    /* 确认密码校验 */
    confirmBlur(rule, value, callback) {
      let {
        pwd
      } = this.registerData;
      if (value === "" | !value) {
        callback(new Error(this.translateTitle("请输入确认密码")));
      } else if (value !== pwd) {
        callback(new Error(this.translateTitle("两次输入密码不一致!")));
      } else {
        callback();
      }
    },
    filterData(val) {
      var str = this.$refs.searchSelect.$data.selectedLabel;
      if (str.length > 6) {
        this.$refs.searchSelect.$data.selectedLabel = str.substr(0, 5);
      }
    },
    handlePassword(type) {
      if (type == 1) {
        this.passwordType === "password" ? this.passwordType = "" : this.passwordType = "password";
        // this.$nextTick(() => {
        //   this.$refs.password.focus();
        // });
      } else {
        this.pwdTypeConfird === "password" ? this.pwdTypeConfird = "" : this.pwdTypeConfird = "password";
        // this.$nextTick(() => {
        //   this.$refs.password.focus();
        // });
      }
    },

    /* 切换tab */
    changeTab() {
      this.resetForm();
      // this.registerData.invite_code = "";
    },

    // 复制成功
    copySuccess() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制成功`),
        type: "success"
      });
    },
    // 复制失败
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制失败`)
      });
    },
    /**获取goole秘钥 */
    GetGoogleSecret() {
      Account.getAccountGoogleSecret().then(res => {
        if (res.status === 200) {
          this.secret = res.data.secret;
        } else {
          // showMessage({ message: res.msg });
        }
      });
    },
    inviteCodeRealRebateRate(value) {
      if (value.length == 6) {
        Account.inviteCodeRealRebateRate({
          code: value
        }).then(res => {
          if (res.status === 200) {
            this.rebate_value = res.data.target_real_rebate_rate;
          }
        });
      } else {
        this.rebate_value = "--";
      }
    },
    /**复制gogle秘钥 */
    copyCode() {
      var clipboard = new _clipboard.default("#copy_code");
      clipboard.on("success", () => {
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        (0, _message.showMessage)({
          message: "该浏览器不支持自动复制",
          type: "warning"
        });

        // 释放内存
        clipboard.destroy();
      });
    },
    /* 注册点击 */
    async registerClick() {
      let {
        currentType,
        registerData: {
          area,
          mobile,
          email
        }
      } = this;

      // 手机
      if (currentType === "3") {
        this.subType = "手机号";
        this.subTitle = "+" + area + " " + mobile;
        this.codeTitle = this.translateTitle(`手机验证`);
        this.codeVisible = true;
        // const { status } = await sendSignSmsCode({
        const {
          status
        } = await (0, _security.sendSignSmsCodeGeet)({
          type: 2,
          mobile,
          area,
          ...this.geetParam
        });
        if (status == 200) {
          this.$message({
            message: this.translateTitle(`验证码发送成功`),
            type: "success",
            customClass: "zZindex",
            duration: 1000
          });
          this.mobileCountDown.countDownStatus = true;
          this.mobileCountDown.countDown = 60;
        } else {
          this.mobileCountDown.countDownStatus = false;
          this.mobileCountDown.countDown = 0;
        }
        this.mobileCountDown.countDownTimer = setInterval(() => {
          if (this.mobileCountDown.countDown === 0) {
            clearInterval(this.mobileCountDown.countDownTimer);
            this.mobileCountDown.countDownStatus = false;
          }
          this.mobileCountDown.countDown--;
        }, 1000);
        this.codeVisible = true;
        // } else {
        //   this.mobileCountDown.countDownStatus = false;
        // }
        // 邮箱
      } else if (currentType == 2) {
        this.subType = "邮箱";
        this.subTitle = email;
        this.codeTitle = this.translateTitle(`邮箱验证`);
        this.codeVisible = true;

        // const { status } = await sendSignEmailCode({
        const {
          status
        } = await (0, _security.sendSignEmailCodeGeet)({
          type: 32,
          email,
          ...this.geetParam
        });
        if (status == 200) {
          this.$message({
            message: this.translateTitle(`验证码发送成功`),
            type: "success",
            customClass: "zZindex",
            duration: 1000
          });
          this.mobileCountDown.countDownStatus = true;
          this.mobileCountDown.countDown = 60;
        } else {
          this.mobileCountDown.countDownStatus = false;
          this.mobileCountDown.countDown = 0;
        }
        this.mobileCountDown.countDownTimer = setInterval(() => {
          if (this.mobileCountDown.countDown === 0) {
            clearInterval(this.mobileCountDown.countDownTimer);
            this.mobileCountDown.countDownStatus = false;
          }
          this.mobileCountDown.countDown--;
        }, 1000);
      } else {
        let params = {
          ...this.geetParam
        };
        params.username = this.registerData.account;
        params.pwd = (0, _jsMd.default)(this.registerData.pwd);
        params.invite_code = this.registerData.invite_code;
        this.submitLoading = true;
        const {
          data,
          status
        } = await Account.accountSignUpGeet(params);
        this.submitLoading = false;
        if (status == 200) {
          (0, _message.showMessage)({
            message: this.translateTitle(`注册成功`),
            type: "success"
          });
          this.registerSuccess(data, status, params);
        }
      }
    },
    onCloseCode() {
      this.codeData = {
        mobile_code: "",
        email_code: "",
        google_code: ""
      };
      clearInterval(this.mobileCountDown.countDownTimer);
      this.mobileCountDown.countDownStatus = false;
    },
    /* 点击确认 */
    onSubmit() {
      this.$refs.code_ref.validate(async valid => {
        if (!valid) {
          return;
        }
        let params = {
          ...this.registerData,
          ...this.codeData
        };
        params.pwd = (0, _jsMd.default)(params.pwd);
        delete params.confirm_pwd;
        if (this.currentType == 3) {
          // 手机
          delete params.email;
          delete params.email_code;
          params.scenes = "qd001";
          this.submitLoading = true;
          const {
            data,
            status
          } = await Account.mobileReg(params);
          this.submitLoading = false;
          if (status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle(`注册成功`),
              type: "success"
            });
            this.registerSuccess(data, status, params);
          }
          // this.registerSuccess(data, status, params);
        }

        if (this.currentType == 1) {
          //账号
          delete params.account;
          delete params.google_code;
          params.google_secret = this.secret;
          params.username = this.registerData.account;
          params.google_code = this.codeData.google_code;
          params.scenes = "qd001";
          this.submitLoading = true;
          const {
            data,
            status
          } = await Account.accountSignUp(params);
          this.submitLoading = false;
          if (status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle(`注册成功`),
              type: "success"
            });
            this.registerSuccess(data, status, params);
          }
        }
        if (this.currentType == 2) {
          // 邮箱
          delete params.area;
          delete params.mobile;
          delete params.mobile_code;
          params.scenes = "qd001";
          this.submitLoading = true;
          const {
            data,
            status
          } = await Account.emailReg(params);
          this.submitLoading = false;
          if (status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle(`注册成功`),
              type: "success"
            });
            this.registerSuccess(data, status, params);
          }
          // this.registerSuccess(data, status, params);
        }
      });
    },

    registerSuccess(data, status, params) {
      if (status === 200) {
        //TODO 直接登录
        // 邮箱
        if (this.currentType == 2) {
          this.$router.push({
            name: "Login",
            params: {
              username: params.email
            }
          });
          // 手机
        }

        if (this.currentType == 1) {
          this.$router.push({
            name: "Login",
            params: {
              username: params.username
            }
          });
        } else {
          this.$router.push({
            name: "Login",
            params: {
              username: params.mobile
            }
          });
        }
      }
    },
    clickFetch() {
      this.isFetch = true;
      this.isShowGeet = !this.isShowGeet;
      // this.isShow = true;
    },

    /* 倒计时 */
    async fetchCode() {
      let {
        currentType,
        registerData: {
          area,
          mobile,
          email
        }
      } = this;

      // 手机
      if (currentType === "3") {
        if (this.mobileCountDown.countDownStatus) return;
        // if (!mobile || /^\s*$/.test(mobile)) {
        //   this.$refs.register_ref.validateField("mobile");
        //   this.mobileCountDown.countDownStatus = false;
        //   return;
        // }
        // const { status } = await sendSignSmsCode({
        const {
          status
        } = await (0, _security.sendSignSmsCodeGeet)({
          type: 2,
          mobile,
          area,
          ...this.geetParam
        });
        if (status == 200) {
          this.mobileCountDown.countDownStatus = true;
          (0, _message.showMessage)({
            message: this.translateTitle(`验证码发送成功`),
            type: "success"
          });
          this.mobileCountDown.countDown = 60;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
        // 邮箱
      } else {
        if (this.mobileCountDown.countDownStatus) return;
        if (!email || /^\s*$/.test(email)) {
          this.$refs.register_ref.validateField("email");
          this.mobileCountDown.countDownStatus = false;
          return;
        }
        const {
          status
        } = await (0, _security.sendSignEmailCodeGeet)({
          type: 32,
          email,
          ...this.geetParam
        });
        if (status == 200) {
          this.mobileCountDown.countDownStatus = true;
          (0, _message.showMessage)({
            message: this.translateTitle(`验证码发送成功`),
            type: "success"
          });
          this.mobileCountDown.countDown = 60;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
      }
    },
    /* 重置 */
    resetForm() {
      this.$refs.register_ref.resetFields();
    },
    /* 获取手机区号 */
    async getAreaCode() {
      const {
        data
      } = await Settings.apiGetStaticTableInfo(Settings.SETTING_SV_ID.static_sms_country);
      if (!data) {
        return false;
      }
      this.all_areacode = data;
    },
    /* 初始数据 */
    initialData() {
      this.registerData = {
        area: 86,
        mobile: "",
        email: "",
        pwd: "",
        account: "",
        confirm_pwd: "",
        // mobile_code: "",
        // email_code: "",
        // google_code: "",
        invite_code: ""
      };
    }
  },
  watch: {
    currentType(val) {
      // 手机
      if (val == "3") {
        // 停止邮箱相关
        clearInterval(this.emailCountDown.countDownTimer);
        this.emailCountDown.countDown = -1;
        this.emailCountDown.countDownStatus = false;
        clearInterval(this.mobileCountDown.countDownTimer);
        this.mobileCountDown.countDown = -1;
        this.mobileCountDown.countDownStatus = false;
        return;
      }

      // 邮箱
      if (val == "2") {
        // 停止手机相关
        clearInterval(this.mobileCountDown.countDownTimer);
        this.mobileCountDown.countDown = -1;
        this.mobileCountDown.countDownStatus = false;
        return;
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    }),
    googleCode() {
      let name;
      let {
        secret
      } = this;
      let account = this.registerData.account || "";
      name = (0, _encryption.default)(account);
      if (secret) {
        let str = secret.slice(secret.length - 6);
        let val = `otpauth://totp/(${name}-${str})?secret=${secret}`;
        val = val.replace(/\s+/g, "");
        return val;
      } else {
        return "";
      }
    }
  },
  components: {
    InputNumber: _InputNumber.default,
    QrcodeVue: _qrcode.default,
    Vcode: _vuePuzzleVcode.default,
    Geetest: _Geetest.default
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.emailCountDown.countDownTimer);
    clearInterval(this.mobileCountDown.countDownTimer);
    next();
  }
};
exports.default = _default;